const firebaseConfig = {
  projectId: "la-mare-tygxt",
  appId: "1:1094370241250:web:89c58acfcacc1e49aaccbb",
  databaseURL:
    "https://la-mare-tygxt-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "la-mare-tygxt.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyAjzti7aaqy_gooFm4x56jB9KYKrwDXqD0",
  authDomain: "la-mare-tygxt.firebaseapp.com",
  messagingSenderId: "1094370241250",
  measurementId: "G-R99LRV8NPM",
};

export default firebaseConfig;
